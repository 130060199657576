<template>
  <transition name="fade" appear>
    <div>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/practice' }">学练考</el-breadcrumb-item>
        <el-breadcrumb-item :to="{ path: '/practice/exercise' }">练习</el-breadcrumb-item>
        <el-breadcrumb-item>练习结果</el-breadcrumb-item>
        </el-breadcrumb-item>
      </el-breadcrumb>

      <div class="content">
        <div style="font-size:24px">本次正确率</div>
        <div class="num theme-c">43%</div>
        <div class="line"></div>
        <div class="flex justify-around">
          <div>
            <div class="count">00:45</div>
            <div class="f18">练习时间</div>
          </div>
          <div>
            <div class="count">14</div>
            <div class="f18">我的错题</div>
          </div>

          <div>
            <div class="count">40</div>
            <div class="f18">本次答题数</div>
          </div>
        </div>
      </div>

      <div class="btn">
        查看错题
      </div>

    </div>
    </div>
  </transition>
</template>

<script>
export default {

}
</script>

<style lang="less" scoped>
.content {
  margin: 30px auto;
  width: 566px;
  .num {
    margin: 30px 0 30px 0;
    font-size: 72px;
  }
  .line {
    border-bottom: 1px solid #c1c4cc;
    margin-bottom: 50px;
  }
  .count {
    font-size: 24px;
    font-weight: 700;
    margin-bottom: 17px;
  }
}
.btn {
  margin: 60px auto 0;
  color: #db3b3d;
  font-size: 18px;
  text-align: center;
  width: 260px;
  height: 50px;
  line-height: 50px;
  border: 1px solid #db3b3d;
  border-radius: 41px;
}
.f17 {
  font-size: 17px;
}
</style>